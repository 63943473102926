/* eslint-disable react/prop-types */
/* eslint-disable no-useless-return */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import NotificationSystem from "react-notification-system";
import transformDates from "../utils/transformDates";
import PageSpinner from "../components/PageSpinner";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import Layout from "../components/Layout/MainLayout";

const DAPP_SERVER_URL = process.env.REACT_APP_DAPP_SERVER_URL;

const Services = () => {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const params = useParams();
  const history = useHistory();
  const certificateLink = `${window.location.origin}/certificate/${params.id}`;
  const notificationSystem = useRef(null);

  const notify = (message, level) => {
    if (notificationSystem && notificationSystem.current) {
      notificationSystem.current.addNotification({
        message,
        level,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await axios.post(
          `${DAPP_SERVER_URL}/service/byCertificate`,
          {
            certificateLink,
            page: 1,
            limit: 20,
          },
          {
            validateStatus: (status) =>
              (status >= 200 && status < 300) || status === 500,
          }
        );
        if (res?.data?.message) {
          throw res?.data?.message;
        }
        if (res && res.data) {
          const { results } = res.data;
          setServices(results && results.length ? results : []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        notify(error.message || error || "Something went wrong", "error");
      }
    })();
  }, []);

  const requestService = (service) => {
    history.push("/serviceRequest", { service });
  };

  return (
    <>
      <PageSpinner showLoader={loading} />
      <Layout
        menu={[
          {
            label: "Back",
            onClick: () => {
              history.goBack();
            },
          },
        ]}
      >
        <div className="mx-2 px-2 mx-md-3 mx-lg-5">
          <Row className="m-0" style={{ minHeight: "72vh" }}>
            <Col xs={12} sm={12}>
              <Card mt="5" className="card-main px-2">
                <CardBody>
                  <h5>Services</h5>
                  {services && services.length ? (
                    <Row className="pt-3">
                      {services.map((service, index) => (
                        <Col
                          xs={12}
                          sm={6}
                          lg={3}
                          md={4}
                          className="mb-3 pr-0"
                          key={index.toString()}
                        >
                          <Card className="card p-3 h-100">
                            <div className="d-flex flex-column justify-content-between h-100">
                              <div style={{ color: "#333333" }}>
                                <h6
                                  style={{
                                    fontSize: "1.15rem",
                                    color: "black",
                                  }}
                                >
                                  {service.title}
                                </h6>
                                <Label className="mt-1 mb-2">
                                  Available on:{" "}
                                  {transformDates(service.availableOn)}
                                </Label>
                                <Label className="shrink-text-card pt-2 border-top">
                                  {service.description}
                                </Label>
                              </div>
                              <Button
                                variant="contained"
                                type="button"
                                size="md"
                                style={{ backgroundColor: "#577ff7" }}
                                className="border-0 mt-3 w-100"
                                onClick={() => requestService(service)}
                              >
                                Request Service
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <p className="text-center p-2">No services found</p>
                  )}
                </CardBody>
              </Card>
            </Col>
            <NotificationSystem
              ref={notificationSystem}
              style={NOTIFICATION_SYSTEM_STYLE}
              dismissible={false}
            />
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default Services;
