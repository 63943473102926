/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable-next-line import/no-named-as-default */
/* eslint-disable import/no-named-as-default */
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import NotFound from "./pages/NotFound";
import ContactRequest from "./pages/ContactRequest";
import Services from "./pages/Services";
import ServiceRequest from "./pages/ServiceRequest";
import Certificates from "./pages/Certificates";
import Home from "./pages/Home";
import VideoPlayer from "./pages/VideoPlayer";
import ProfilePage from "./pages/ProfilePage";
import VideosPage from "./pages/VideosPage";
import ContactUs from "./pages/ContactUs";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route
          exact
          path="/certificate/:id"
          render={(props) => <Certificates {...props} />}
        />
        <Route
          exact
          path="/contactRequest/:id"
          render={(props) => <ContactRequest {...props} />}
        />
        <Route
          exact
          path="/service/:id"
          render={(props) => <Services {...props} />}
        />
        <Route
          exact
          path="/contactUs"
          render={(props) => <ContactUs {...props} />}
        />
        <Route
          exact
          path="/serviceRequest"
          render={(props) => <ServiceRequest {...props} />}
        />
        <Route
          exact
          path="/video"
          render={(props) => <VideoPlayer {...props} />}
        />
        <Route
          exact
          path="/profile/:id"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          exact
          path="/videos/:id"
          render={(props) => <VideosPage {...props} />}
        />
        <Route exact path="/home" render={(props) => <Home {...props} />} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
export default App;
