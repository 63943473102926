/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-return */
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import "react-phone-input-2/lib/bootstrap.css";
import NotificationSystem from "react-notification-system";
import PageSpinner from "../components/PageSpinner";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import ErrorMsg from "../components/ErrorMsg";
import transformDates from "../utils/transformDates";

const fields = {
  email: {
    name: "Email ID",
    regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    required: true,
  },
  message: {
    name: "Message",
    required: true,
  },
};

const DAPP_SERVER_URL = process.env.REACT_APP_DAPP_SERVER_URL;

const ServiceRequest = () => {
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [service, setService] = useState(null);
  const history = useHistory();
  const defaultValues = {
    email: "",
    message: "",
  };
  const [formData, setFormData] = useState(defaultValues);
  const notificationSystem = useRef(null);

  const notify = (message, level) => {
    if (notificationSystem && notificationSystem.current) {
      notificationSystem.current.addNotification({
        message,
        level,
      });
    }
  };

  useEffect(() => {
    if (history && history.location && history.location.state?.service) {
      setService(history.location.state.service);
    } else history.push("/home");
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!service) {
        throw new Error("Service not found");
      }
      const res = await axios.post(
        `${DAPP_SERVER_URL}/serviceRequest`,
        {
          serviceId: service._id || "",
          formData,
        },
        {
          validateStatus: (status) =>
            (status >= 200 && status < 300) || status === 500,
        }
      );
      if (res?.data?.message) {
        throw res?.data?.message;
      }
      setLoading(false);
      notify("Service request sent successfully!", "success");
      setFormData(defaultValues);
      setBtnDisabled(true);
      setLoading(true);
      setTimeout(() => history.goBack(), 2000);
    } catch (error) {
      setLoading(false);
      notify(error.message || error || "Something went wrong", "error");
    }
  };

  const isFormInvalid = () => {
    let invalid = false;
    Object.keys(formData).forEach((k) => {
      if (!formData[k] && fields[k] && fields[k].required) {
        invalid = true;
        return;
      }
    });
    Object.keys(errors).forEach((k) => {
      if (errors[k]) {
        invalid = true;
        return;
      }
    });
    return invalid;
  };

  const handleErrors = (key, value) => {
    const tempErrors = errors;
    if (fields[key].required && !value) {
      tempErrors[key] = `${fields[key].name} is required`;
      setErrors({ ...tempErrors });
    } else if (value && fields[key].regex && !fields[key].regex.test(value)) {
      tempErrors[key] = `Invalid ${fields[key].name}`;
      setErrors({ ...tempErrors });
    } else {
      tempErrors[key] = "";
      setErrors({ ...tempErrors });
    }
  };

  const onBlur = (key, value) => {
    handleErrors(key, value);
    setBtnDisabled(isFormInvalid());
  };

  const onChange = (key, value) => {
    handleErrors(key, value);
    const temp = formData;
    temp[key] = value;
    setFormData({ ...temp });
    setBtnDisabled(isFormInvalid());
  };

  return (
    <>
      <PageSpinner showLoader={loading} />
      <Row
        className="w-100 mx-auto justify-content-center align-items-center"
        style={{
          height: "100vh",
        }}
      >
        <Col md={12} lg={8} className="py-4">
          <Card body className="p-4 pt-5 card-main">
            <Label
              style={{
                cursor: "pointer",
                color: "blue",
                position: "absolute",
                zIndex: 99,
                right: 12,
                top: 8,
              }}
              onClick={() => history.goBack()}
            >
              Cancel
            </Label>
            <Row className="w-100 justify-content-center mx-auto">
              <Col
                sm={12}
                md={6}
                lg={service?.description.length > 40 ? 7 : 6}
                className="pe-md-4"
              >
                <div style={{ color: "#333333" }}>
                  <h6 style={{ fontSize: "1.15rem", color: "black" }}>
                    {service?.title}
                  </h6>
                  <Label className="mt-1 mb-3">
                    Available on: {transformDates(service?.availableOn)}
                  </Label>
                  <p
                    className="pt-3 border-top"
                    style={{ textAlign: "justify" }}
                  >
                    {service?.description}
                  </p>
                </div>
              </Col>
              <Col
                sm={12}
                md={6}
                lg={service?.description.length > 40 ? 5 : 6}
                className="border-md-start border-sm-top pt-4 mt-4 pt-sm-3 mt-sm-3 mt-md-0 pt-md-0 ps-md-4"
              >
                <Form onSubmit={onSubmit}>
                  <div className="text-center pb-3">
                    <h6 style={{ fontSize: "1.15rem", color: "black" }}>
                      Request Service
                    </h6>
                  </div>
                  <FormGroup>
                    <Label for="user-mail">
                      Email ID<span className="text-danger"> *</span>
                    </Label>
                    <Input
                      type="text"
                      value={formData.email}
                      required
                      name="user-mail"
                      id="user-mail"
                      className="mb-1 mt-1"
                      placeholder="Email ID"
                      onChange={(e) => onChange("email", e.target.value.trim())}
                      onBlur={(e) => onBlur("email", e.target.value.trim())}
                    />
                    <ErrorMsg errors={errors} field="email" />
                  </FormGroup>
                  <FormGroup>
                    <Label className="mt-2" for="message">
                      Message<span className="text-danger"> *</span>
                    </Label>
                    <Input
                      type="textarea"
                      value={formData.message}
                      required
                      name="message"
                      id="message"
                      className="mb-1 mt-1"
                      placeholder="Message"
                      onChange={(e) => onChange("message", e.target.value)}
                      onBlur={(e) => onBlur("message", e.target.value)}
                    />
                    <ErrorMsg errors={errors} field="message" />
                  </FormGroup>
                  <Button
                    variant="contained"
                    disabled={btnDisabled}
                    type="submit"
                    size="md"
                    className="border-0 mt-3 w-100"
                    style={{ backgroundColor: "#577ff7" }}
                  >
                    Request Service
                  </Button>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
        <NotificationSystem
          ref={notificationSystem}
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </Row>
    </>
  );
};

export default ServiceRequest;
