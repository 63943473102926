/* eslint-disable  */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  Stylesheet,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../../assests/img/logo/ablab-logo-updated.png";
import '../../../styles/style.scss';

/* const styles = {
    iconMobile: {
      float: 'right',
      position: 'absolute',
      left: 80,
      top:5
    },
    icon: {
      float:'right',
      top: 5,
      position: 'absolute',

    }
};
 */

const Topbar = ({ toggleSidebar, menu }) => {
  const [topbarIsOpen, setTopbarOpen] = useState(false);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);

  return (
    <Navbar
      color="right"
      light
      className="navbar shadow-sm p-3 mb-4 bg-white rounded pe-lg-1"
      expand="lg"
      sticky="top"
    >
      {/* <Button
        className="border-0 shadow"
        style={{ backgroundColor: "#fff", color: "#466ad8" }}
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button> */}
      

      {menu && menu.length ? (
        <>
          <NavbarToggler onClick={toggleTopbar} className="border-0 p-0 ">
            <div
              className="menu-btn btn btn-secondary border-0"
              style={{ 
              backgroundColor: "#fff", 
              color: "#466ad8", 
              width: 45,
              display: 'flex',
              fontSize: 26,
              height: 45,
              justifyContent: 'center',
              alignItems: 'center'
              
             }}
            >    
              <FontAwesomeIcon icon={faBars} />
            </div>
          </NavbarToggler>
          <Collapse isOpen={topbarIsOpen} navbar>
            <Nav className="ms-auto mt-3 mt-lg-0" navbar>
              {menu
                .filter((item) => item.to || item.onClick)
                .map((item, index) =>
                  item.to ? (
                    <NavItem key={`${item.label + index}`}>
                      <NavLink
                        className={`topbar-link ${
                          item.active && "link-active"
                        }`}
                        to={item.to}
                        tag={Link}
                      >
                        {item.label}
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavItem
                      key={`${item.label + index}`}
                      style={{ cursor: "pointer" }}
                    >
                      <NavLink
                        className={`topbar-link ${
                          item.active && "link-active"
                        }`}
                        onClick={item.onClick}
                      >
                        {item.label}
                      </NavLink>
                    </NavItem>
                  )
                )}
            </Nav>
          </Collapse>
        </>
      ) : null}
      <img src={logo} alt="logo" height={"35px"} className="logo"/>
    </Navbar>
  );
};

export default Topbar;
