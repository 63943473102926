/* eslint-disable */
import React from "react";
import { Redirect } from "react-router-dom";
import "../styles/certificate.css";
import PropTypes from "prop-types";
import { Button, Col, Container, Row } from "reactstrap";
import {
  HiOutlineDocumentText,
  HiOutlinePlay,
  HiOutlinePrinter,
} from "react-icons/hi";
import QrCodeWithLogo from "qrcode-with-logos";
import logo from "../assests/img/logo/ablab-logo-updated.png";
import logoGIF from "../assests/img/logo/logoGIF.gif";
import design from "../assests/img/design.png";
import request from "../Server";
import ShareModal from "../components/ShareModal";
import Layout from "../components/Layout/MainLayout";
import PageSpinner from "../components/PageSpinner";
import Moment from "react-moment";
import { Badge, Modal, ListGroup } from "react-bootstrap";

class Certificates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      data: [],
      set: false,
      shareModal: false,
      loadingData: false,
      contractAddress: "",
      servicesModal: false,
      hideParagraphInPrint: false,
      menu: [
        {
          label: "My Complete Profile",
          onClick: this.openProfilePage,
        },
        {
          label: "My Services",
          onClick: () => {
            this.setState({ servicesModal: true });
          },
        },
        {
          label: "Share",
          onClick: this.openShareModal,
        },
        {
          label: "Contact Me",
          to: `/contactRequest/${props.match.params.id}`,
        },
        {
          label: "Contact My Phygital World",
          onClick: this.openPhygitalWorld,
        },
      ],
    };
  }

  async componentDidMount() {
    const { id } = this.state;
    this.setState({ loadingData: true });
    const details = await this.getCertificateDetails(id);
    if (details?.status === "revoked") {
      console.log("this certificate has been revoked");
      return;
    }
    this.setState({ data: details, set: true });
    let qrCodeLogo = null;
    if (details && details.base64Image) {
      qrCodeLogo = {
        src: details.base64Image,
        logoSize: 0.19,
      };
    }
    const qrcode = new QrCodeWithLogo({
      canvas: document.getElementById("canvas"),
      errorCorrectionLevel: "H",
      content: window.location.href,
      width: 68,
      image: document.getElementById("image"),
      logo: qrCodeLogo,
    });

    qrcode.toCanvas();

    this.verifyOnBlockchain();
  }

  openPhygitalWorld = () => {
    const { history } = this.props;
    history.push(`/contactUs`);
    //window.open("https://www.myphygitalworld.com", "_blank");
  };

  openResumePage = (resumeUrl) => {
    window.open(resumeUrl);
  };

  openVideoPage = (videoUrl) => {
    const { history } = this.props;
    history.push(`/video`, { videoUrl });
  };

  openProfilePage = () => {
    const { history, match } = this.props;
    const { data } = this.state;
    history.push(`/profile/${match.params.id}`, { certificateDetails: data });
  };

  getCertificateDetails = async (id) => {
    const base = `cert/${id}`;
    try {
      const response = await request({
        url: base,
        method: "GET",
      });
      const details = JSON.parse(JSON.stringify(response));
      return details.certificate;
    } catch (error) {
      throw Error("ERRROR");
    }
  };

  openShareModal = () => {
    this.setState({ shareModal: true });
  };

  onPrint = () => {
    this.setState({ hideParagraphInPrint: true });
    setTimeout(() => {
      window.print();
      this.setState({ hideParagraphInPrint: false });
    }, 10);
  };

  verifyOnBlockchain = async () => {
    this.setState({ loadingData: true });
    const base = "admin/certificate/search";
    const response = await request({
      url: base,
      method: "POST",
      data: { issuedTransactionId: window.location.pathname.split("/")[2] },
    });
    const data = JSON.parse(JSON.stringify(response.certificates));
    this.setState({
      loadingData: false,
      contractAddress: data ? data[0]?.issuedTransactionId : "",
    });
    const { menu, contractAddress } = this.state;
    if (contractAddress) {
      this.setState({
        menu: [
          ...menu,
          { label: "View on Blockchain", onClick: this.openOnBlockchain },
        ],
      });
    }
  };

  openOnBlockchain = () => {
    const { contractAddress } = this.state;
    window.open(
      `${process.env.REACT_APP_CONTRACT_BASE}${contractAddress}#code`
    );
  };

  render() {
    const { data, set, shareModal, id, loadingData, menu, servicesModal } =
      this.state;
    if (set === false) {
      return <></>;
    }
    if (data === null) {
      return <Redirect to="/NotFound" />;
    }
    const date = new Date(data.issueDate);
    const expiryDate = new Date(data.expiryDate);
    return (
      <Layout menu={menu} footer={false}>
        <PageSpinner showLoader={loadingData} />
        {!this.state.hideParagraphInPrint &&
          <Container className="d-flex">
            When you look at any of our certificates, you will observe they are all stored as a first step onto our blockchain platform . By starting with connecting you first to blockchain technology, you will be in a position to enjoy a growing number of selected benefits that come with this new technology. Where the future of technology goes , you go as well! And because we have first authenticated you with your personal identity key, you will then be able to take your "passport" with you wherever your physical/ digital travels take you. Once you access the blockchain stored credential for each MY PHYGITAL KEY holder, you can then select all the menu items you wish to view about that particular key holder.
          </Container>
        }
        <Container className="d-flex justify-content-end align-items-center top-btns pe-lg-0 ">
          {/* {data && data.videoUrl && (
            <HiOutlinePlay
              className="icon-btn"
              style={{ color: "rgb(202, 15, 71)" }}
              onClick={() => this.openVideoPage(data.videoUrl)}
            />
          )}
          {data && data.resumeUrl && (
            <HiOutlineDocumentText
              style={{ color: "#577ff7" }}
              className="icon-btn"
              onClick={() => this.openResumePage(data.resumeUrl)}
            />
          )} */}
          <HiOutlinePrinter
            className="icon-btn text-success"
            onClick={this.onPrint}
          />
          {/* <HiOutlineShare
            className="icon-btn text-success"
            onClick={this.openShareModal}
          /> */}
        </Container>
        <div className="container pb-5">
          <div>
            <div className="border-pattern">
              <div className="content">
                <div className="inner-content">
                  <div className="logo-row">
                    <img src={logoGIF} alt="logo" id="logo" style={{ marginRight: "10px", width: "11%" }} />
                    <img src={logo} alt="logo" id="logo" style={{ marginLeft: "10px" }} />
                  </div>
                  <h1>Certificate of Completion</h1>
                  <div className="design-row">
                    <img src={design} alt="design" id="design" />
                  </div>
                  <p id="cert-to">This Certificate Is Proudly Presented To</p>
                  <p>
                    <span id="cert-holder">{data.professionalName}</span>
                    {/* {data.linkedInUrl && (
                      <a
                        className="linkedin-logo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={data.linkedInUrl}
                      >
                        <ImLinkedin style={{ marginTop: "-8px" }} />
                      </a>
                    )} */}
                  </p>
                  <p id="cert-course">{data.field}</p>
                  <Container>
                    <Row>
                      <Col lg={7} md={7} sm={6} xs={12}>
                        <p className="cert-dates">ISSUE DATE</p>
                        <p className="cert-day">
                          <b>
                            {date
                              .toLocaleString("default", { month: "short" })
                              .toUpperCase()}{" "}
                            {date.getDate()},&nbsp;
                            {date.getFullYear()}
                          </b>
                        </p>
                        <p className="cert-dates">EXPIRATION DATE</p>
                        <p className="cert-day">
                          {data.expiryDate ? (
                            <b>
                              {expiryDate
                                .toLocaleString("default", { month: "short" })
                                .toUpperCase()}{" "}
                              {expiryDate.getDate()},&nbsp;
                              {expiryDate.getFullYear()}
                            </b>
                          ) : (
                            <b>N/A</b>
                          )}
                        </p>
                      </Col>
                      <Col lg={5} md={5} sm={6} xs={12} align="center">
                        <div className="key-border">
                          <canvas id="canvas" />
                        </div>
                      </Col>
                      <p id="cert-bot">
                        This Certificate of Completion confirms the successful
                        completion of the continuing education course specified
                        above and does not convey any right to use THE AB LAB®,
                        THE AB LAB PRO™, PHYGITIZED™, MY PHYGITAL KEY™, MY
                        PHYGITAL WORLD™ or any other word marks, design marks
                        and trade dress of The Ab Lab. Copyright ©{" "}
                        <Moment format="YYYY">{new Date()}</Moment> The Ab Lab.
                        All rights reserved.
                      </p>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
        {servicesModal && (
          <Modal
            show={servicesModal}
            onHide={() => {
              this.setState({ servicesModal: false });
            }}
            backdrop="static"
            className="share-modal"
          >
            <Modal.Header closeButton closeLabel="">
              <Modal.Title>Services</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="row">
                <Col xs={12} sm={12}>
                  <ListGroup>
                    {data?.services.map((service, index) => (
                      <>
                        <ListGroup.Item>{service}</ListGroup.Item>
                      </>
                    )
                    )}
                  </ListGroup>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="default"
                onClick={() => {
                  this.setState({ servicesModal: false });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {shareModal &&
          <ShareModal
            open={shareModal}
            onClose={() => {
              this.setState({ shareModal: false });
            }}
            shareUrl={`${process.env.REACT_APP_CLIENT_URL}/certificate/${id}`}
            title="Share"
            base64Image={data.base64Image}
          />
        }
      </Layout>
    );
  }
}

Certificates.propTypes = {
  // eslint-disable-next-line react/require-default-props
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  // eslint-disable-next-line react/require-default-props
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default Certificates;
