/* eslint-disable  */
import React from "react";
import Footer from "./Footer";
import Topbar from "./content/Topbar";

function Wrapper(props) {
  return <div>{props.children}</div>;
}

function Layout({ menu, footer = true, children }) {
  return (
    <>
      <Topbar menu={menu} />
      <Wrapper>
        <main>{children}</main>
        {footer && <Footer />}
      </Wrapper>
    </>
  );
}

export default Layout;
