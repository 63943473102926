/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable */

import React from "react";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  usePagination,
} from "react-table";
import { Table } from "reactstrap";
const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

export default function TableData({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination,
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    () => {}
  );

  var pagesArray = [];
  for (var i = 1; i <= pageOptions.length; i++) {
    pagesArray.push(<option value={i}>{i}</option>);
  }

  return (
    <div {...getTableProps()} className="table">
      <div className="row mb-3">
        <div className="col-md-4 col-lg-4 col-xl-4">
          <div className="d-flex align-items-center">
            <div className="mt-1 me-2">
              <label>Shown</label>
            </div>
            <div>
              <select
                name="pageLimit"
                className="form-control px-2 py-1 mt-1"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-1 ms-2">
              <label>entries</label>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-4 col-xl-4">
          <div className="d-flex justify-content-center align-items-center">
            <div className="mt-2 pr-2">
              <label>
                {pageIndex + 1} of {pageOptions.length}
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-4 col-xl-4">
          <div className="d-flex justify-content-end align-items-center">
            <div sm="4" className="me-2">
              <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                class="rounded-circle btn btn-secondary d-flex justify-center align-items-center"
                style={{ width: "35px", height: "35px", padding: 0 }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="24px"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ margin: "0 auto" }}
                >
                  <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
                </svg>
              </button>
            </div>
            <div sm="4">
              <select
                name="filter"
                value={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                className="form-control px-3 py-1"
                // style={{ marginTop: 6}}
              >
                {pagesArray}
              </select>
            </div>
            <div sm="4" className="ms-2 ">
              <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                class="rounded-circle btn btn-secondary d-flex justify-center align-items-center"
                style={{ width: "35px", height: "35px", padding: 0 }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="24px"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ margin: "0 auto" }}
                >
                  <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div />
      <Table responsive bordered>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className="border">
              {headerGroup.headers.map((column) => (
                <th className="border">{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr>
                {row.cells.map((cell) => {
                  return <td>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
