/* eslint-disable */

import React from "react";
import request from "../Server";
import Table from "./Table";
import { Modal, Card } from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/style.scss";
import Layout from "../components/Layout/MainLayout";
import PageSpinner from "../components/PageSpinner";
import * as GeneralFunctions from "../GeneralFunctions";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      accountAddress: null,
      pageData: [],
      loadingData: true,
      searchInput: "",
      show: false,
      signedBy: null,
      msgParams: null,
      selectedData: [],
    };
  }

  filterById(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject["_id"] == id;
    })[0];
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = (event) => {
    const selectedObject = this.filterById(this.state.data, event.target.value);
    let showPopup = GeneralFunctions.getUserShowCertificatePopup();
    if (showPopup || showPopup === false) {
      this.viewCertificatePage(selectedObject.issuedTransactionId);
    } else {
      this.setState({ show: true, selectedData: selectedObject });
    }
  };

  componentDidMount() {
    const getData = async () => {
      this.setState({ loadingData: true });
      const base = "admin/certificate/search";
      const response = await request({
        url: base,
        method: "POST",
        data: { status: "issued", public: "true" },
      });

      this.setState({
        data: JSON.parse(JSON.stringify(response.certificates)),
        pageData: JSON.parse(JSON.stringify(response.certificates)),
        loadingData: false,
      });
    };
    if (this.state.loadingData) {
      getData();
    }
  }

  viewCertificatePage(id) {
    this.props.history.push("/certificate/" + id);
    /* window.open(
      `${process.env.REACT_APP_CLIENT_URL}/certificate/${id}`,
      "_blank"
    ); */
  }

  viewOnBlockchain() {
    const contractAddress = this.state.selectedData.issuedTransactionId;
    this.setState({ show: false });
    window.open(
      `${process.env.REACT_APP_CONTRACT_BASE}${contractAddress}#code`
    );
  }

  handleRevoke = (event) => {
    this.revokeCertificate(event.target.value);
  };

  revokeCertificate = async (certificateID) => {
    const user = GeneralFunctions.getAuthUser();
    console.log(certificateID);
    const base = `/admin/revokeRequest`;
    try {
      const response = await request({
        url: base,
        method: "POST",
        data: { certificateId: certificateID, user: user },
      });
    } catch (error) {
      alert(error);
      throw Error("ERRROR");
    }
  };

  globalSearch = (searchInput) => {
    let filteredData = this.state.pageData.filter((value) => {
      return (
        value.professionalName
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.issuerId.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  render() {
    const columns = [
      {
        Header: "Name",
        accessor: "professionalName",
      },
      {
        Header: "Created By",
        accessor: "issuerId",
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: ({ cell }) => {
          // alert(id)
          return (
            <>
              <Button
                type="button"
                value={cell.row.values._id}
                color="info"
                style={{ marginRight: 15 }}
                onClick={this.handleShow}
                className="btn-sm text-white"
              >
                View
              </Button>
            </>
          );
        },
      },
    ];
    return (
      <>
        <PageSpinner showLoader={this.state.loadingData} />
        <Layout>
          <div className="container-fluid">
            <Row className="mt-3 mx-1" style={{ minHeight: "72vh" }}>
              <Col xs={12} sm={12}>
                <Card className="card-main">
                  <Card.Body className="pb-0">
                    <Row className="mb-4">
                      <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <h4>Issued Blockchain Certificates of Completion</h4>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="btn btn-secondary">
                              <span>
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  className="text-white"
                                />
                              </span>
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            style={{ marginLeft: -5 }}
                            size="large"
                            name="searchInput"
                            onChange={(e) => {
                              if (e.target.value)
                                this.globalSearch(e.target.value);
                              else this.setState({ data: this.state.pageData });
                            }}
                            label="Search"
                            placeholder="Search Certificate No., Name, ID ..."
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Table columns={columns} data={this.state.data} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              backdrop="static"
              keyboard={false}
              style={{ padding: "12px" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Certificate Overview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <p>
                  ID: {this.state.selectedData._id}
                  <br />
                  Name: {this.state.selectedData.professionalName}
                  <br />
                  Course: {this.state.selectedData.field}
                </p> */}
                <h6>WELCOME TO OUR FIRST TIME VIEWERS</h6>
                <p>
                  Before you explore the content for any of our MY PHYGITAL KEY
                  holders, please note :
                </p>
                <p>
                  When you look at any of our certificates, you will observe
                  they are all stored as a first step onto our blockchain
                  platform . By starting with connecting you first to blockchain
                  technology, you will be in a position to enjoy a growing
                  number of selected benefits that come with this new
                  technology. Where the future of technology goes , you go as
                  well! And because we have first authenticated you with your
                  personal identity key, you will then be able to take your
                  “passport” with you wherever your physical/ digital travels
                  take you.
                </p>
                <p>
                  Once you access the blockchain stored credential for each MY
                  PHYGITAL KEY holder, you can then select all the menu items
                  you wish to view about that particular key holder.
                </p>
                <p>
                  <Input
                    type="checkbox"
                    onChange={(e) => {
                      e.target.checked
                        ? GeneralFunctions.setUserShowCertificatePopup()
                        : localStorage.removeItem("showCertificatePopUp");
                    }}
                  />
                  <span style={{ marginLeft: "6px" }}>
                    Please do not show this popup again.
                  </span>
                </p>
              </Modal.Body>

              <Modal.Footer>
                {/* <Button color="secondary" onClick={this.handleClose}>
                  Close
                </Button> */}

                <Button
                  color="primary"
                  style={{ visibility: this.state.view ? "hidden" : "visible" }}
                  onClick={() =>
                    this.viewCertificatePage(
                      this.state.selectedData.issuedTransactionId
                    )
                  }
                >
                  View Certificate and Profile
                </Button>
                <Button
                  color="primary"
                  style={{ visibility: this.state.view ? "hidden" : "visible" }}
                  onClick={() =>
                    this.viewOnBlockchain(
                      this.state.selectedData.issuedTransactionId
                    )
                  }
                >
                  View on Blockchain
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Layout>
      </>
    );
  }
}

export default Home;
