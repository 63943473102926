import React from "react";
import Moment from "react-moment";

const Footer = () => {
  return (
    <div className="container-fluid text-center d-flex justify-content-center align-items-center mt-3 pb-3">
      <small className="text-muted">
        This Certificate of Completion confirms the successful completion of the
        continuing education course specified above and does not convey any
        right to use THE AB LAB®, THE AB LAB PRO™, PHYGITIZED™, MY PHYGITAL
        KEY™, MY PHYGITAL WORLD™ or any other word marks, design marks and trade
        dress of The Ab Lab. Copyright ©{" "}
        <Moment format="YYYY">{new Date()}</Moment> The Ab Lab. All rights
        reserved.
      </small>
    </div>
  );
};

export default Footer;
