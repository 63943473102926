import React, { useEffect, useRef, useState } from "react";
import NotificationSystem from "react-notification-system";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import PageSpinner from "../components/PageSpinner";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";

const VideoPlayer = () => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(null);
  const [playing, setPlaying] = useState(false);
  const notificationSystem = useRef(null);
  const notify = (message, level) => {
    if (notificationSystem && notificationSystem.current) {
      notificationSystem.current.addNotification({
        message,
        level,
      });
    }
  };
  const history = useHistory();

  const onError = () => {
    notify(
      "Something went wrong, Redirecting back to certificate page.",
      "error"
    );
    setLoading(false);
    setPlaying(false);
    setTimeout(() => {
      history.goBack();
    }, 3000);
  };

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.videoUrl)
        setUrl(history.location.state.videoUrl);
      else {
        onError();
      }
    } else {
      history.push("/home");
    }
  }, []);

  const onReady = () => {
    setLoading(false);
    setPlaying(true);
  };

  return (
    <>
      <PageSpinner showLoader={loading} />
      {url && (
        <div className="player-wrapper">
          <ReactPlayer
            playing={playing}
            onReady={onReady}
            onBufferEnd={onReady}
            onError={onError}
            className="react-player"
            width="100%"
            height="100%"
            controls
            loop
            fallback={() => <PageSpinner showLoader />}
            url={url}
          />
        </div>
      )}
      <NotificationSystem
        ref={notificationSystem}
        style={NOTIFICATION_SYSTEM_STYLE}
      />
    </>
  );
};

export default VideoPlayer;
