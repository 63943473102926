/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { Button, Col, Row } from "reactstrap";
import QrCodeWithLogo from "qrcode-with-logos";

const ShareModal = ({
  open = false,
  // eslint-disable-next-line prettier/prettier
  onClose = () => { },
  shareUrl,
  title,
  base64Image,
}) => {
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setTimeout(() => {
      let qrCodeLogo = null;
      if (base64Image) {
        qrCodeLogo = {
          src: base64Image,
          logoSize: 0.19,
        };
      }
      const qrcode = new QrCodeWithLogo({
        canvas: document.getElementById("canvas-share-small"),
        errorCorrectionLevel: "H",
        content: window.location.href,
        width: 65,
        image: document.getElementById("image"),
        logo: qrCodeLogo,
      });
      qrcode.toCanvas();
    }, 100);
  }, []);

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      className="share-modal"
    >
      <Modal.Header closeButton closeLabel="">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="share-wrapper">
          <FacebookShareButton className="share-btn" url={shareUrl}>
            <FacebookIcon round />
          </FacebookShareButton>
          <WhatsappShareButton className="share-btn" url={shareUrl}>
            <WhatsappIcon round />
          </WhatsappShareButton>
          <TwitterShareButton className="share-btn" url={shareUrl}>
            <TwitterIcon round />
          </TwitterShareButton>
          <LinkedinShareButton className="share-btn" url={shareUrl}>
            <LinkedinIcon round />
          </LinkedinShareButton>
          <TelegramShareButton className="share-btn" url={shareUrl}>
            <TelegramIcon round />
          </TelegramShareButton>
        </div>
        <div style={{ textAlign: "center", paddingBottom: 10, paddingTop: 10 }}>
          <div style={{ marginBottom: 5 }}>Or copy the link below</div>
          <a href={shareUrl} className="wrap-long-text">
            {shareUrl}
          </a>
        </div>
        <Row style={{ justifyContent: "center" }}>
          <Col lg={5} md={5} sm={6} xs={12} align="center">
            <div className="key-border">
              <canvas id="canvas-share-small" style={{ marginTop: 14 }} />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button color="default" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
