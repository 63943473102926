export const setAuthorizationHeader = (authorization) => {
  localStorage.setItem("authorization", authorization);
};

export const setAuthUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getAuthUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const setUserPermission = (permissions) => {
  localStorage.setItem("permissions", JSON.stringify(permissions));
};

export const getUserPermission = () => {
  return JSON.parse(localStorage.getItem("permissions"));
};

export const setUserRole = (role) => {
  localStorage.setItem("role", JSON.stringify(role));
};

export const getUserRole = () => {
  return JSON.parse(localStorage.getItem("role"));
};

export const setUserShowCertificatePopup = () => {
  localStorage.setItem("showCertificatePopUp", false);
};

export const getUserShowCertificatePopup = () => {
  return localStorage.getItem("showCertificatePopUp");
};

export const stringTruncate = (value, limit) => {
  if (!value) {
    return value;
  }
  // eslint-disable-next-line no-param-reassign
  limit = limit || 50;
  return value.length > limit ? `${value.substring(0, limit)}...` : value;
};

export const capitalize = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const clearLocalStorage = (refreshApp) => {
  localStorage.removeItem("user");
  localStorage.removeItem("authorization");
  localStorage.removeItem("permissions");
  localStorage.removeItem("showCertificatePopUp");
  if (refreshApp) {
    window.location.reload();
  }
};
