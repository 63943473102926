import moment from "moment";

const transformDates = (dates) => {
  if (!dates || !Array.isArray(dates) || !dates.length) {
    return "-";
  }
  return dates.map((date) => moment(date).format("MM/DD/YYYY")).join(", ");
};

export default transformDates;
