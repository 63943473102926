/* eslint-disable */
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import NotificationSystem from "react-notification-system";
import * as _ from "lodash";
import PageSpinner from "../components/PageSpinner";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import ErrorMsg from "../components/ErrorMsg";
import request from "../Server";

const fields = {
  firstName: {
    name: "First Name",
    required: true,
  },
  middleName: {
    name: "Middle Name",
    required: false,
  },
  lastName: {
    name: "Last Name",
    required: true,
  },
  email: {
    name: "Email ID",
    regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    required: true,
  },
  phoneNumber: {
    name: "Phone",
    required: false,
  },
  message: {
    name: "Message",
    required: true,
  },
};

const DAPP_SERVER_URL = process.env.REACT_APP_DAPP_SERVER_URL;

const ContactRequest = () => {
  const [loading, setLoading] = useState(false);
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const params = useParams();
  const history = useHistory();
  const certificateLink = `${window.location.origin}/certificate/${params.id}`;
  const defaultValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };
  const [formData, setFormData] = useState(defaultValues);
  const notificationSystem = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await request({
          url: `cert/${params.id}`,
          method: "GET",
        });
        let details;
        if (response) {
          details = JSON.parse(JSON.stringify(response));
          setCertificateDetails(details?.certificate);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        notify(error.message || error || "Something went wrong", "error");
      }
    })();
  }, []);

  const notify = (message, level) => {
    if (notificationSystem && notificationSystem.current) {
      notificationSystem.current.addNotification({
        message,
        level,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const professionalEmail = certificateDetails.professionalEmail;
    if (!professionalEmail) {
      notify("Contact details are not updated by professional, please drop a email to admin no-reply[at]theablab[dot]com", "error");
      return;
    } else {
      try {
        setLoading(true);
        const res = await axios.post(
          `${DAPP_SERVER_URL}/contactRequest`,
          {
            certificateLink,
            professionalEmail,
            formData,
          },
          {
            validateStatus: (status) =>
              (status >= 200 && status < 300) || status === 500,
          }
        );
        setLoading(false);
        notify("Contact request sent successfully!", "success");
        setFormData(defaultValues);
      } catch (error) {
        setLoading(false);
        notify(error.message || error || "Something went wrong", "error");
      }
    }
  };

  const isFormInvalid = () => {
    let invalid = false;
    Object.keys(formData).forEach((k) => {
      if (!formData[k] && fields[k] && fields[k].required) {
        invalid = true;
        return;
      }
    });
    Object.keys(errors).forEach((k) => {
      if (errors[k]) {
        invalid = true;
        return;
      }
    });
    return invalid;
  };

  const handleErrors = (key, value) => {
    const tempErrors = errors;
    if (fields[key].required && !value) {
      tempErrors[key] = `${fields[key].name} is required`;
      setErrors({ ...tempErrors });
    } else if (value && fields[key].regex && !fields[key].regex.test(value)) {
      tempErrors[key] = `Invalid ${fields[key].name}`;
      setErrors({ ...tempErrors });
    } else {
      tempErrors[key] = "";
      setErrors({ ...tempErrors });
    }
  };

  const onBlur = (key, value) => {
    handleErrors(key, value);
    setBtnDisabled(isFormInvalid());
  };

  const onChange = (key, value) => {
    handleErrors(key, value);
    const temp = formData;
    temp[key] = value;
    setFormData({ ...temp });
    setBtnDisabled(isFormInvalid());
  };

  const onPhoneChange = (value, data, e) => {
    const classes = e.target.className;
    const tempErr = errors;
    if (classes.includes("invalid-number")) {
      tempErr.phoneNumber = "Invalid Phone";
      setErrors({ ...tempErr });
    } else {
      tempErr.phoneNumber = "";
      setErrors({ ...tempErr });
    }
    const rawPhone = value.slice(data.dialCode.length);
    const temp = formData;
    temp.phoneNumber = rawPhone ? value : "";
    setFormData({ ...temp });
    setBtnDisabled(isFormInvalid());
  };

  const isPhoneValid = (value, country, countries) => {
    return countries.some((c) => {
      return _.startsWith(value, c.dialCode) || _.startsWith(c.dialCode, value);
    });
  };

  return (
    <>
      <PageSpinner showLoader={loading} />
      <Row
        className="w-100 mx-auto"
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col md={6} lg={4} className="py-4">
          <Card body className="p-4 card-main">
            <Form onSubmit={onSubmit}>
              <div className="text-center pb-3">
                <h5>Request Contact</h5>
              </div>
              <FormGroup>
                <Label className="mt-2" for="firstname">
                  First Name<span className="text-danger"> *</span>
                </Label>
                <Input
                  type="text"
                  value={formData.firstName}
                  required
                  name="firstname"
                  id="firstname"
                  className="mb-1 mt-1"
                  placeholder="First Name"
                  onChange={(e) => onChange("firstName", e.target.value.trim())}
                  onBlur={(e) => onBlur("firstName", e.target.value.trim())}
                />
                <ErrorMsg errors={errors} field="firstName" />
              </FormGroup>
              <FormGroup>
                <Label className="mt-2" for="middleName">
                  Middle Name
                </Label>
                <Input
                  type="text"
                  value={formData.middleName}
                  name="middleName"
                  id="middleName"
                  className="mb-1 mt-1"
                  placeholder="Middle Name"
                  onChange={(e) =>
                    onChange("middleName", e.target.value.trim())
                  }
                  onBlur={(e) => onBlur("middleName", e.target.value.trim())}
                />
                <ErrorMsg errors={errors} field="middleName" />
              </FormGroup>
              <FormGroup>
                <Label className="mt-2" for="lastName">
                  Last Name<span className="text-danger"> *</span>
                </Label>
                <Input
                  type="text"
                  value={formData.lastName}
                  required
                  name="lastName"
                  id="lastName"
                  className="mb-1 mt-1"
                  placeholder="Last Name"
                  onChange={(e) => onChange("lastName", e.target.value.trim())}
                  onBlur={(e) => onBlur("lastName", e.target.value.trim())}
                />
                <ErrorMsg errors={errors} field="lastName" />
              </FormGroup>
              <FormGroup>
                <Label className="mt-2" for="user-mail">
                  Email ID<span className="text-danger"> *</span>
                </Label>
                <Input
                  type="text"
                  value={formData.email}
                  required
                  name="user-mail"
                  id="user-mail"
                  className="mb-1 mt-1"
                  placeholder="Email ID"
                  onChange={(e) => onChange("email", e.target.value.trim())}
                  onBlur={(e) => onBlur("email", e.target.value.trim())}
                />
                <ErrorMsg errors={errors} field="email" />
              </FormGroup>
              <FormGroup>
                <Label className="mt-2" for="phone">
                  Phone
                </Label>
                <PhoneInput
                  country="us"
                  inputProps={{
                    name: "phone",
                  }}
                  enableSearch
                  containerClass="mb-1 mt-1"
                  searchPlaceholder="Search country"
                  value={formData.phoneNumber}
                  onChange={onPhoneChange}
                  isValid={isPhoneValid}
                />
                <ErrorMsg errors={errors} field="phoneNumber" />
              </FormGroup>
              <FormGroup>
                <Label className="mt-2" for="message">
                  Message<span className="text-danger"> *</span>
                </Label>
                <Input
                  type="textarea"
                  value={formData.message}
                  required
                  name="message"
                  id="message"
                  className="mb-1 mt-1"
                  placeholder="Message"
                  onChange={(e) => onChange("message", e.target.value)}
                  onBlur={(e) => onBlur("message", e.target.value)}
                />
                <ErrorMsg errors={errors} field="message" />
              </FormGroup>
              <Button
                variant="contained"
                disabled={btnDisabled}
                type="submit"
                size="md"
                className="border-0 mt-3 w-100"
                style={{ backgroundColor: "#577ff7" }}
              >
                Request Contact
              </Button>
              <Button
                variant="contained"
                type="button"
                size="md"
                className="border-0 mt-2 w-100"
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </Button>
            </Form>
          </Card>
        </Col>
        <NotificationSystem
          ref={notificationSystem}
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </Row>
    </>
  );
};

export default ContactRequest;
