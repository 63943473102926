/* eslint-disable*/

import React, { useState, useEffect, useRef } from "react";
import NotificationSystem from "react-notification-system";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "reactstrap";
import { Modal, Badge, ListGroup } from "react-bootstrap";
import QrCodeWithLogo from "qrcode-with-logos";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
} from "react-share";
import InstagramPng from "../assests/img/instagram.png";
import GooglePlusPng from "../assests/img/googleplus.png";
import Layout from "../components/Layout/MainLayout";
import PageSpinner from "../components/PageSpinner";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import AvatarSvg from "../assests/img/avatar.svg";
import VideoSvg from "../assests/img/video.svg";
import ResumeSvg from "../assests/img/resume.svg";
import CertificateSvg from "../assests/img/certificate.svg";
import TestimonialPng from "../assests/img/testimonial.png";
import SocialMediaLinkPng from "../assests/img/social-media-link.png";
import ServicePng from "../assests/img/service.png";
import FacilityLocationPng from "../assests/img/facility-location.png";
import ShareModal from "../components/ShareModal";
import request from "../Server";
import { HiDownload } from "react-icons/hi";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

const ProfilePage = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [shareModal, setShareModal] = useState(false);
  const [downloadKeyModal, setDownloadKeyModal] = useState(false);
  const [testimonialsModal, setTestimonialsModal] = useState(false);
  const [socialMediaLinksModal, setSocialMediaLinksModal] = useState(false);
  const [servicesModal, setServicesModal] = useState(false);
  const [facilityLocationModal, setFacilityLocationModal] = useState(false);
  const params = useParams();
  const certificateLink = `${window.location.origin}/certificate/${params.id}`;
  const notificationSystem = useRef(null);
  const qrRefSmall = useRef(null);
  const qrRefBig = useRef(null);
  const history = useHistory();

  const notify = (message, level) => {
    if (notificationSystem && notificationSystem.current) {
      notificationSystem.current.addNotification({
        message,
        level,
      });
    }
  };

  const renderPhygitalKey = (details, qrRef, id) => {
    let qrCodeLogo = null;
    if (details?.base64Image) {
      qrCodeLogo = {
        src: details.base64Image,
        logoSize: 0.2,
      };
    }
    if (qrRef && qrRef.current) {
      const qrcode = new QrCodeWithLogo({
        canvas: document.getElementById(id),
        errorCorrectionLevel: "H",
        content: `${window.location.origin}/certificate/${params.id}`,
        width: 512,
        image: document.getElementById("image"),
        logo: qrCodeLogo,
      });
      qrcode.toCanvas();
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await request({
          url: `cert/${params.id}`,
          method: "GET",
        });
        let details;
        if (response) {
          details = JSON.parse(JSON.stringify(response));
          setCertificateDetails(details?.certificate);
        }
        renderPhygitalKey(details?.certificate, qrRefSmall, "canvas-small");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        notify(error.message || error || "Something went wrong", "error");
      }
    })();
  }, []);

  const openShareModal = () => {
    setShareModal(true);
  };
  const openResume = () => {
    window.open(certificateDetails?.resumeUrl, "_blank");
  };

  const openCertificate = () => {
    history.push(`/certificate/${params.id}`);
  };

  const openVideo = () => {
    history.push(`/videos/${params.id}`);
  };

  const downloadKey = () => {
    domtoimage
      .toBlob(document.getElementById("profile-phygital-key-big"), {
        quality: 1,
        style: {
          margin: 0,
          padding: 0,
        },
      })
      .then((blob) => saveAs(blob, "my-phygital-key.png"));
  };

  return (
    <Layout
      menu={[
        {
          label: "My Complete Profile",
          to: "#",
          active: true,
        },
        {
          label: "My Services",
          onClick: () => setServicesModal(true),
        },
        {
          label: "Share",
          onClick: () => openShareModal(),
        },
        {
          label: "Contact Me",
          to: `/contactRequest/${params.id}`,
        },
        {
          label: "Contact My Phygital World",
          to: `/contactUs`,
        },
        ...(params.id && [
          {
            label: "View on Blockchain",
            onClick: () =>
              window.open(`${process.env.REACT_APP_CONTRACT_BASE}${params.id}`),
          },
        ]),
      ]}
    >
      <PageSpinner showLoader={loading} />
      <Row
        className="m-0 mt-4 mx-1 mx-lg-5 px-lg-5 profile-page"
        style={{ minHeight: "72vh" }}
      >
        <Col xs={12} sm={12}>
          <Card className="card-main m-0 p-4 p-lg-5">
            {certificateDetails ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Row className="profile-row-1 w-100 d-flex align-items-center">
                  <Col xs={12} sm={12} md={6} lg={7} xl={8}>
                    <div className="w-100 profile-info ">
                      <h4 className="profile-name text-center text-md-start">
                        {certificateDetails?.professionalName}
                      </h4>
                      <p className="mt-2 w-100 profile-about">
                        {certificateDetails?.about}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={5} xl={4}>
                    <div className="h-100 w-100 d-flex justify-content-center align-items-center mb-4 mb-md-0 mt-2 mt-md-0 ">
                      <img
                        alt="Profile photo"
                        className={`img-fluid ${certificateDetails?.base64Image && "shadow"
                          }`}
                        src={certificateDetails?.base64Image || AvatarSvg}
                      />
                    </div>
                  </Col>
                </Row>
                <Row
                  className="profile-row-2 text-center mt-2 mt-md-4 pb-3"
                  style={{ display: "flex", width: "100%" }}
                >
                  {
                    certificateDetails.showCertificateSection &&
                    <Col xs={12} sm={12} md={6} lg={3} className="mt-4 mt-lg-4 mb-3">
                      <div className="w-100 text-center">
                        <h5>Certificate</h5>
                        <img
                          className="profile-other-img img-fluid mt-3 w-100 ms-3"
                          src={CertificateSvg}
                        />
                        <Button
                          color="primary"
                          className="profile-btns px-4 mt-4 mb-1 mb-lg-0"
                          onClick={() => openCertificate()}
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  }
                  {
                    certificateDetails.showPhygitalKeySection &&
                    <Col xs={12} sm={12} md={6} lg={3} className="mt-4 mt-lg-4 mb-3">
                      <div className="w-100">
                        <h5>My Phygital Key</h5>
                        <div
                          className="profile-phygital-key w-100 mt-3"
                          id="profile-phygital-key-small"
                        >
                          <canvas ref={qrRefSmall} id="canvas-small" />
                        </div>
                        <Button
                          color="primary"
                          className="profile-btns px-4 mt-1 mb-1 mb-lg-0"
                          onClick={() => openShareModal()}
                        >
                          Share
                        </Button>
                        <Button
                          color="success"
                          className="mt-lg-1 ms-1"
                          onClick={() => {
                            setDownloadKeyModal(true);
                            setTimeout(() => {
                              renderPhygitalKey(
                                certificateDetails,
                                qrRefBig,
                                "canvas-big"
                              );
                            }, 100);
                          }}
                          style={{ height: "36px" }}
                        >
                          <HiDownload
                            style={{
                              fontSize: 24,
                              height: 24,
                              width: 24,
                              marginTop: "-5px",
                            }}
                          />
                        </Button>
                      </div>
                    </Col>
                  }
                  {
                    certificateDetails.showResumeSection &&
                    <Col xs={12} sm={12} md={6} lg={3} className="mt-4 mt-lg-4 mb-3">
                      <div className="w-100">
                        <h5>Resume</h5>
                        <img
                          className="profile-other-img img-fluid mt-3 w-100"
                          src={ResumeSvg}
                        />
                        <Button
                          color="primary"
                          className="profile-btns px-4 mt-4 mb-1 mb-lg-0"
                          onClick={() => openResume()}
                          disabled={!certificateDetails?.resumeUrl}
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  }
                  {
                    certificateDetails.showVideoSection &&
                    <Col xs={12} sm={12} md={6} lg={3} className="mt-4 mt-lg-4 mb-3">
                      <div className="w-100">
                        <h5>Video</h5>
                        <img
                          className="profile-other-img img-fluid mt-3 w-100"
                          src={VideoSvg}
                        />
                        <Button
                          color="primary"
                          className="profile-btns px-4 mt-4 mb-1 mb-lg-0"
                          onClick={() => openVideo()}
                          disabled={!certificateDetails?.videos && !certificateDetails?.videos.length}
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  }

                  {
                    certificateDetails.showTestimonialSection &&
                    <Col xs={12} sm={12} md={6} lg={3} className="mt-4 mt-lg-4 mb-3">
                      <div className="w-100 text-center">
                        <h5>Testimonials</h5>
                        <img
                          className="profile-other-img img-fluid mt-3 w-100 ms-3"
                          src={TestimonialPng}
                        />
                        <Button
                          color="primary"
                          className="profile-btns px-4 mt-4 mb-1 mb-lg-0"
                          onClick={() => setTestimonialsModal(true)}
                          disabled={
                            !certificateDetails?.testimonials ||
                            !certificateDetails?.testimonials.length
                          }
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  }
                  {
                    certificateDetails.showSocialLinksSection &&
                    <Col xs={12} sm={12} md={6} lg={3} className="mt-4 mt-lg-4 mb-3">
                      <div className="w-100 text-center">
                        <h5>Social media links</h5>
                        <img
                          className="profile-other-img img-fluid mt-3 w-100"
                          src={SocialMediaLinkPng}
                        />
                        <Button
                          color="primary"
                          className="profile-btns px-4 mt-4 mb-1 mb-lg-0"
                          onClick={() => setSocialMediaLinksModal(true)}
                          disabled={
                            !certificateDetails?.socialFacebookURL &&
                            !certificateDetails?.socialInstagramURL &&
                            !certificateDetails?.socialLinkedInURL &&
                            !certificateDetails?.socialTwitterURL &&
                            !certificateDetails?.socialGooglePlusURL &&
                            !certificateDetails?.socialPInterestURL
                          }
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  }
                  {
                    certificateDetails.showServicesSection &&
                    <Col xs={12} sm={12} md={6} lg={3} className="mt-4 mt-lg-4 mb-3">
                      <div className="w-100 text-center">
                        <h5>Services</h5>
                        <img
                          className="profile-other-img img-fluid mt-3 w-100"
                          src={ServicePng}
                        />
                        <Button
                          color="primary"
                          className="profile-btns px-4 mt-4 mb-1 mb-lg-0"
                          onClick={() => setServicesModal(true)}
                          disabled={
                            !certificateDetails?.services ||
                            !certificateDetails?.services.length
                          }
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  }
                  {
                    certificateDetails.showFacilityLocationSection &&
                    <Col xs={12} sm={12} md={6} lg={3} className="mt-4 mt-lg-4 mb-3">
                      <div className="w-100 text-center">
                        <h5>Facility Location</h5>
                        <img
                          className="profile-other-img img-fluid mt-3 w-100"
                          src={FacilityLocationPng}
                        />
                        <Button
                          color="primary"
                          className="profile-btns px-4 mt-4 mb-1 mb-lg-0"
                          onClick={() => setFacilityLocationModal(true)}
                          disabled={!certificateDetails?.facilityName}
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  }
                </Row>

                <Modal
                  show={downloadKeyModal}
                  onHide={() => {
                    setDownloadKeyModal(false);
                  }}
                  backdrop="static"
                  className="share-modal"
                >
                  <Modal.Header closeButton closeLabel="">
                    <Modal.Title>My Phygital Key</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row">
                      <Col xs={12} sm={12}>
                        <div
                          className="profile-phygital-key-big w-100 mt-3"
                          id="profile-phygital-key-big"
                        >
                          <canvas
                            ref={qrRefBig}
                            id="canvas-big"
                            style={{ top: 35, left: 140, position: "absolute" }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      color="primary"
                      className="profile-btns"
                      onClick={() => downloadKey()}
                    >
                      Download
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={testimonialsModal}
                  onHide={() => {
                    setTestimonialsModal(false);
                  }}
                  backdrop="static"
                  className="share-modal"
                >
                  <Modal.Header closeButton closeLabel="">
                    <Modal.Title>Testimonials</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row">
                      <Col xs={12} sm={12}>
                        <ListGroup>
                          {certificateDetails?.testimonials.map(
                            (testimonial, index) => (
                              <>
                                <ListGroup.Item>{testimonial}</ListGroup.Item>
                              </>
                            )
                          )}
                        </ListGroup>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      color="default"
                      onClick={() => {
                        setTestimonialsModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={socialMediaLinksModal}
                  onHide={() => {
                    setSocialMediaLinksModal(false);
                  }}
                  backdrop="static"
                  className="share-modal"
                >
                  <Modal.Header closeButton closeLabel="">
                    <Modal.Title>Social Media Links</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row">
                      <Col xs={4} sm={3}>
                        <FacebookIcon round size="25" />
                      </Col>
                      <Col xs={8} sm={9}>
                        {certificateDetails?.socialFacebookURL || "-"}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={4} sm={3}>
                        <img
                          style={{ height: 23, width: 23, marginLeft: 2 }}
                          src={GooglePlusPng}
                        />
                      </Col>
                      <Col xs={8} sm={9}>
                        {certificateDetails?.socialGooglePlusURL || "-"}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={4} sm={3}>
                        <PinterestIcon round size="25" />
                      </Col>
                      <Col xs={8} sm={9}>
                        {certificateDetails?.socialPInterestURL || "-"}
                      </Col>
                    </Row>

                    <Row className="row mt-2">
                      <Col xs={4} sm={3}>
                        <TwitterIcon round size="25" />
                      </Col>
                      <Col xs={8} sm={9}>
                        {certificateDetails?.socialTwitterURL || "-"}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={4} sm={3}>
                        <LinkedinIcon round size="25" />
                      </Col>
                      <Col xs={8} sm={9}>
                        {certificateDetails?.socialLinkedInURL || "-"}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={4} sm={3}>
                        <img
                          style={{ height: 23, width: 23, marginLeft: 2 }}
                          src={InstagramPng}
                        />
                      </Col>
                      <Col xs={12} sm={8}>
                        {certificateDetails?.socialInstagramURL || "-"}
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      color="default"
                      onClick={() => {
                        setSocialMediaLinksModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={servicesModal}
                  onHide={() => {
                    setServicesModal(false);
                  }}
                  backdrop="static"
                  className="share-modal"
                >
                  <Modal.Header closeButton closeLabel="">
                    <Modal.Title>Services</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row">
                      <Col xs={12} sm={12}>
                        <ListGroup>
                          {certificateDetails?.services.map(
                            (service, index) => (
                              <>
                                <ListGroup.Item>{service}</ListGroup.Item>
                              </>
                            )
                          )}
                        </ListGroup>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      color="default"
                      onClick={() => {
                        setServicesModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={facilityLocationModal}
                  onHide={() => {
                    setFacilityLocationModal(false);
                  }}
                  backdrop="static"
                  className="share-modal"
                >
                  <Modal.Header closeButton closeLabel="">
                    <Modal.Title>Facility Location</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="row">
                      <Col xs={12} sm={4}>
                        Facility Name:
                      </Col>
                      <Col xs={12} sm={8}>
                        {certificateDetails?.facilityName}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={12} sm={4}>
                        Address:
                      </Col>
                      <Col xs={12} sm={8}>
                        {certificateDetails?.facilityAddress}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={12} sm={4}>
                        City:
                      </Col>
                      <Col xs={12} sm={8}>
                        {certificateDetails?.facilityCity}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={12} sm={4}>
                        State/Province:
                      </Col>
                      <Col xs={12} sm={8}>
                        {certificateDetails?.facilityState}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={12} sm={4}>
                        Zip code:
                      </Col>
                      <Col xs={12} sm={8}>
                        {certificateDetails?.facilityZipCode}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={12} sm={4}>
                        Country:
                      </Col>
                      <Col xs={12} sm={8}>
                        {certificateDetails?.facilityCountry}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={12} sm={4}>
                        Work Email:
                      </Col>
                      <Col xs={12} sm={8}>
                        {certificateDetails?.facilityWorkEmail}
                      </Col>
                    </Row>
                    <Row className="row mt-2">
                      <Col xs={12} sm={4}>
                        Phone Number:
                      </Col>
                      <Col xs={12} sm={8}>
                        {certificateDetails?.facilityPhoneNumber}
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      color="default"
                      onClick={() => {
                        setFacilityLocationModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            ) : !loading ? (
              <div className="p-5 d-flex justify-content-center align-items-center">
                Profile information not available
              </div>
            ) : (
              <></>
            )}
          </Card>
        </Col>
      </Row>
      <NotificationSystem
        ref={notificationSystem}
        style={NOTIFICATION_SYSTEM_STYLE}
        dismissible={false}
      />
      {shareModal && (
        <ShareModal
          open={shareModal}
          onClose={() => {
            setShareModal(false);
          }}
          shareUrl={`${process.env.REACT_APP_CLIENT_URL}/certificate/${params.id}`}
          title="Share"
          base64Image={certificateDetails?.base64Image}
        />
      )}
    </Layout>
  );
};

export default ProfilePage;
