import React from "react";
import "../styles/NotFound.css";

const NotFound = () => (
  <div id="main">
    <div className="fof">
      <h1>Error 404</h1>
      <h6>Invalid URL/Page Not Found</h6>
    </div>
  </div>
);

export default NotFound;
