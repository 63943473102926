/* eslint-disable */
import axios from "axios";
import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import NotificationSystem from "react-notification-system";
import * as _ from "lodash";
import PageSpinner from "../components/PageSpinner";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import ErrorMsg from "../components/ErrorMsg";

const fields = {
    firstName: {
        name: "First Name",
        required: true,
    },
    middleName: {
        name: "Middle Name",
        required: false,
    },
    lastName: {
        name: "Last Name",
        required: true,
    },
    emailAddress: {
        name: "Email ID",
        regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        required: true,
    },
    zipCode: {
        name: "zipCode",
        regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
        required: true,
    },
    message: {
        name: "Message",
        required: true,
    },
};

const ContactUs = () => {
    const [loading, setLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const params = useParams();
    const history = useHistory();
    const defaultValues = {
        firstName: "",
        lastName: "",
        middleName: "",
        emailAddress: "",
        zipCode: "",
        message: "",
    };
    const [formData, setFormData] = useState(defaultValues);
    const notificationSystem = useRef(null);

    const notify = (message, level) => {
        if (notificationSystem && notificationSystem.current) {
            notificationSystem.current.addNotification({
                message,
                level,
            });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const obj = {
                url: 'auth/certificateContactUs',
                method: 'POST',
                data: formData
            }
            const response = await axios.create({
                baseURL: process.env.REACT_APP_DAPP_SERVER_URL,
                headers: {},
            })(obj);
            notify(response.data.message, "success");
            setFormData(defaultValues);
            setBtnDisabled(true);
            setLoading(false);
        } catch (error) {
            let errorMessage = error.response.data && error.response.data.message
            notify(errorMessage || "Something went wrong", "error");
            setLoading(false);
        }
    };

    const isFormInvalid = () => {
        let invalid = false;
        Object.keys(formData).forEach((k) => {
            if (!formData[k] && fields[k] && fields[k].required) {
                invalid = true;
                return;
            }
        });
        Object.keys(errors).forEach((k) => {
            if (errors[k]) {
                invalid = true;
                return;
            }
        });
        return invalid;
    };

    const handleErrors = (key, value) => {
        const tempErrors = errors;
        if (fields[key].required && !value) {
            tempErrors[key] = `${fields[key].name} is required`;
            setErrors({ ...tempErrors });
        } else if (value && fields[key].regex && !fields[key].regex.test(value)) {
            tempErrors[key] = `Invalid ${fields[key].name}`;
            setErrors({ ...tempErrors });
        } else {
            tempErrors[key] = "";
            setErrors({ ...tempErrors });
        }
    };

    const onBlur = (key, value) => {
        handleErrors(key, value);
        setBtnDisabled(isFormInvalid());
    };

    const onChange = (key, value) => {
        handleErrors(key, value);
        const temp = formData;
        temp[key] = value;
        setFormData({ ...temp });
        setBtnDisabled(isFormInvalid());
    };

    return (
        <>
            <Row
                className="w-100 mx-auto"
                style={{
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Col md={6} lg={4} className="py-4">
                    <PageSpinner showLoader={loading} />
                    <Card body className="p-4 card-main">
                        <Form onSubmit={onSubmit}>
                            <div className="text-center pb-3">
                                <h5>Contact Us</h5>
                            </div>
                            <FormGroup>
                                <Label className="mt-2" for="firstname">
                                    First Name<span className="text-danger"> *</span>
                                </Label>
                                <Input
                                    type="text"
                                    value={formData.firstName}
                                    required
                                    name="firstName"
                                    id="firstName"
                                    className="mb-1 mt-1"
                                    placeholder="First Name"
                                    onChange={(e) => onChange("firstName", e.target.value.trim())}
                                    onBlur={(e) => onBlur("firstName", e.target.value.trim())}
                                />
                                <ErrorMsg errors={errors} field="firstName" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mt-2" for="middleName">
                                    Middle Name
                                </Label>
                                <Input
                                    type="text"
                                    value={formData.middleName}
                                    name="middleName"
                                    id="middleName"
                                    className="mb-1 mt-1"
                                    placeholder="Middle Name"
                                    onChange={(e) =>
                                        onChange("middleName", e.target.value.trim())
                                    }
                                    onBlur={(e) => onBlur("middleName", e.target.value.trim())}
                                />
                                <ErrorMsg errors={errors} field="middleName" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mt-2" for="lastName">
                                    Last Name<span className="text-danger"> *</span>
                                </Label>
                                <Input
                                    type="text"
                                    value={formData.lastName}
                                    required
                                    name="lastName"
                                    id="lastName"
                                    className="mb-1 mt-1"
                                    placeholder="Last Name"
                                    onChange={(e) => onChange("lastName", e.target.value.trim())}
                                    onBlur={(e) => onBlur("lastName", e.target.value.trim())}
                                />
                                <ErrorMsg errors={errors} field="lastName" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mt-2" for="zipCode">
                                    ZIP code<span className="text-danger"> *</span>
                                </Label>
                                <Input
                                    type="text"
                                    value={formData.zipCode}
                                    required
                                    name="zipCode"
                                    id="zipCode"
                                    className="mb-1 mt-1"
                                    placeholder="ZIP code"
                                    onChange={(e) => onChange("zipCode", e.target.value.trim())}
                                    onBlur={(e) => onBlur("zipCode", e.target.value.trim())}
                                />
                                <ErrorMsg errors={errors} field="zipCode" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mt-2" for="user-mail">
                                    Email Address<span className="text-danger"> *</span>
                                </Label>
                                <Input
                                    type="text"
                                    value={formData.emailAddress}
                                    required
                                    name="emailAddress"
                                    id="emailAddress"
                                    className="mb-1 mt-1"
                                    placeholder="Email Address"
                                    onChange={(e) => onChange("emailAddress", e.target.value.trim())}
                                    onBlur={(e) => onBlur("emailAddress", e.target.value.trim())}
                                />
                                <ErrorMsg errors={errors} field="emailAddress" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mt-2" for="message">
                                    Message<span className="text-danger"> *</span>
                                </Label>
                                <Input
                                    type="textarea"
                                    value={formData.message}
                                    required
                                    name="message"
                                    id="message"
                                    className="mb-1 mt-1"
                                    placeholder="Message"
                                    onChange={(e) => onChange("message", e.target.value)}
                                    onBlur={(e) => onBlur("message", e.target.value)}
                                />
                                <ErrorMsg errors={errors} field="message" />
                            </FormGroup>
                            <Button
                                variant="contained"
                                disabled={btnDisabled}
                                type="submit"
                                size="md"
                                className="border-0 mt-3 w-100"
                                style={{ backgroundColor: "#577ff7" }}
                            >
                                Send Message
                            </Button>
                            <Button
                                variant="contained"
                                type="button"
                                size="md"
                                className="border-0 mt-2 w-100"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                Back
                            </Button>
                        </Form>
                    </Card>
                </Col>
                <NotificationSystem
                    ref={notificationSystem}
                    style={NOTIFICATION_SYSTEM_STYLE}
                />
            </Row>
        </>
    )
}

export default ContactUs;
